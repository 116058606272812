<template>
  <v-layout column align-start justify-start class="my-1 mx-0">
    <v-layout row class="ma-0" align-center>
    <v-btn class="mr-2" :disabled="disabled" icon v-if="deletable" @click="$store.commit('ticketOrder/removeVisitor', index)">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-flex xs12 class="caption mb-2 flex-none margin-auto">
      {{$t('ticket_order.visitor', { number: index + 1 })}}
    </v-flex>
    </v-layout>
    <v-layout row align-center class="my-1 mx-0">
      <v-flex xs12 class="mb-2">
        <v-autocomplete
          :label="$t('fields.pass')"
          outlined
          class="mx-2 required"
          hide-details
          v-model="pass"
          :item-text="(item) => localize({ array: item.name })"
          item-value="id"
          :items="passes"
          autofocus
          :filter="autocompleteFilter"
        >
          <template v-slot:selection="{ item }">
            {{ localize({ array: item.name }) }}
          </template>
          <template v-slot:item="{ item }">
            {{ localize({ array: item.name }) }}
          </template>
        </v-autocomplete>
      </v-flex>
      <v-flex xs12>
        <v-layout row class="wrap-none ma-0">
        <v-text-field
          xs6
          :disabled="disabled"
          :label="$t('fields.name')"
          outlined
          class="mx-2 required"
          hide-details
          v-model="name"
        >
        </v-text-field>
        <v-text-field
          xs6
          :disabled="disabled"
          :label="$t('fields.last_name')"
          outlined
          class="mx-2 required"
          hide-details
          v-model="lastName"
        >
        </v-text-field>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import { i18n } from '@/locale'
import { mapState } from 'vuex'
import localizationMixins from '@/mixins/Localization'
import searchMixins from '@/mixins/Search'
export default {
  props: {
    deletable: Boolean,
    index: Number,
    disabled: Boolean
  },
  mixins: [
    localizationMixins,
    searchMixins
  ],
  data () {
    return {
      i18n
    }
  },
  computed: {
    ...mapState({
      visitors: state => state.ticketOrder.visitors,
      passes: state => state.passes.list
    }),
    name: {
      get () {
        return this.visitors[this.index].name
      },
      set (value) {
        this.$store.commit('ticketOrder/editVisitor', { index: this.index, visitor: { name: value } })
      }
    },
    lastName: {
      get () {
        return this.visitors[this.index].lastName
      },
      set (value) {
        this.$store.commit('ticketOrder/editVisitor', { index: this.index, visitor: { lastName: value } })
      }
    },
    pass: {
      get () {
        return this.visitors[this.index].pass
      },
      set (value) {
        this.$store.commit('ticketOrder/editVisitor', { index: this.index, visitor: { pass: value } })
      }
    }
  }
}
</script>
